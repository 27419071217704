html[data-theme="dark"] {
  --bg: #333333;
  --bg-panel: #434343;
  --color-heading: #0077ff;
  --color-text: #b5b5b5;
}
@font-face {
  font-family: 'Nexa';
  src: url('/public/fonts/NexaLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('/public/fonts/NexaBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Nexa';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-family: 'Nexa';
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .panel-default {
  text-align: center;
  position: absolute;
  top: 1rem !important;
  right: 1rem !important;
  max-height: 50% !important;
  width: 20% !important;
  min-width: 300px !important;
  overflow: auto !important;
  background: #181b1f !important;
  border: 1px solid #ccccdc12 !important;
  color: #ccccdc;
} */
.panel-reservation {
  position: fixed;
  margin-right: -20px;
  /* margin-top: 20px; */
  height: -webkit-fill-available;
  bottom: 1.1rem !important;
  right: 1rem !important;
  max-height: 100% !important;
  width: 103% !important;
  min-width: 300px !important;
  overflow: auto !important;
  background: rgba(0, 0, 0, 0.95) !important;
  /* border: 1px solid #ccccdc12 !important; */
  color: #ccccdc;
  line-height: 1.7em;
  zoom: 1.05;
}

.panel-loading {
  position: absolute;
  top: 45% !important;
  right: 35% !important;
  max-height: 50% !important;
  width: 20% !important;
  min-width: 300px !important;
  background: transparent !important;
}

.panel-body {
  height: auto;
  font-size: 1.1em;
  padding: 30px;
  text-align: center;
  justify-content:center;
  display: flex;
}

@media (max-width : 720px) {
  .panel-reservation {width: 103% !important; height: 95% !important; max-height: 95% !important; margin-left: -20px;}
  .panel-reservation p {font-size: 1.2em;}
  .panel-default {display: contents;}
  .dark-form {zoom:0.75}
}


.panel-body.hidden {
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  height: 0;
}

.panel-heading {
  font-size: 1.2em;
}

.panel-default>.panel-heading {
  background-color: rgb(40, 42, 53) !important;
  border-color: rgb(40, 42, 53) !important;
  color: #ccccdc;
}

.panel-heading {
  background-color: rgb(40, 42, 53) !important;
  border-color: rgb(40, 42, 53) !important;
  color: #ccccdc;
}

.dark-form {
  line-height: inherit;
}

.dark-form input[type="text"],
.dark-form input[type="email"],
.dark-form input[type="password"],
.dark-form input[type="date"],
.dark-form textarea {
    width: 100%;
    padding: 5px 10px 5px 12px;
    margin: 10px 0;
    background-color: rgba(234,234,234, 0.9);
    border: 1px solid #555;
    color: black;
    border-radius: 22px;
    margin-left: 5px;
}

.dark-form input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

.dark-form input[type="date"] {
  position: relative;
}

.dark-form input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

.dark-form input[type="date"]::selection {
  background-color: rgba(234,234,234, 0.9);
}

.dark-form input[type="submit"],
.dark-form button {
    background-color: rgb(30, 42, 33);
    /* color: #ffffff; */
    padding: 5px 30px 5px 30px;
    font-size: large;
    border: 1px solid;
    border-radius: 8px;
    cursor: pointer;
}
.dark-form select {
  width: 100%;
    padding: 10px;
    margin: 10px 0px 10px 5px;
    background-color: rgba(234,234,234, 0.9);
    border: 1px solid #555;
    color: black;
    border-radius: 22px;
}
.dark-form input[type="submit"]:hover,
.dark-form button:hover {
    background-color: #ef6932;

}

.dark-form label {
    display: block;
    margin-bottom: 5px;
}


.whiteline {
  width: 26px;
  height: 2px;
  background-color: #ccccdcd3;
  margin: 5px 0;
}

.tacbox {
  margin-bottom: 7px;
}

.terms-container {
  padding: 20px;
}

.options {
  display: flex;
  gap: 20px;
}

.option input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  display: inline-block;
  visibility: hidden;
}

.option input:checked {
  background-color: #FFA500;
  border-color: #FFA500;
  color: black;
  visibility: hidden;
}

.option input:checked + label {
  color: black;
  border: 2px solid #FFA500;
  background-color: #FFA500;
}

.option label {
  color: black;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid #ddd;
  background-color: rgba(234,234,234, 0.9);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  min-width: 100px;
  text-align: center;
  font-weight: lighter;
  font-family: 'Nexa';
}