body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.marker {
  background-image: url("../public/stop.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.location-pin {
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 5px solid #FF8838;
  background-color: #FF8838;
}

.maplibregl-popup-content,
.mapboxgl-popup-content {
  font-size: 1.6rem !important;
  background: #FF8838 !important;
  padding: 5px 5px 5px 5px !important;
}

.maplibregl-popup-tip {
  border-top-color: #ef6932 !important;
  border-bottom-color: #ef6932 !important;
}

.panel-body {
  padding-top: 20px;
}

.list-group {
  margin-top: 30px;
}

.list-group-item {
  background: transparent;
  border: 1px solid #ccccdc12;
}

.loader {
  border: 16px solid #ffffff;
  border-top: 16px solid #ef6932;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}